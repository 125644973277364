import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d2218a22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_error_message = _resolveComponent("error-message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_row, { class: "ion-justify-content-end" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.done())),
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { icon: _ctx.checkmark }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString(_ctx.langGeneric.t("done")), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }),
    _createVNode(_component_error_message, {
      light: "",
      value: _ctx.error,
      class: "error-right"
    }, null, 8, ["value"])
  ]))
}