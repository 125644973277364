
import { popoverController, IonIcon, IonButton } from "@ionic/vue";
import { defineComponent } from "vue";
import { helpCircleOutline } from "ionicons/icons";

import HelpPopover from "./HelpPopover.vue";

export default defineComponent({
  name: "HelpButton",
  props: {
    path: {
      type: String,
      required: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonButton,
    IonIcon,
  },
  setup(props) {
    const showHelp = async (event: Event) => {
      const popover = await popoverController.create({
        component: HelpPopover,
        componentProps: {
          path: props.path,
        },
        event,
      });
      await popover.present();
    };
    return {
      helpCircleOutline,
      showHelp
    };
  },
});
