
import { defineComponent } from "vue";

import { IonRow, IonButton, IonIcon } from "@ionic/vue";
import { checkmark } from "ionicons/icons";

import ErrorMessage from "../../generic/form/ErrorMessage.vue";

import LanguageService from "../../../lib/service/LanguageService";
import CreateService from "../../../lib/service/CreateService";

export default defineComponent({
  props: {
    error: String,
    disabled: Boolean,
  },
  components: {
    IonRow,
    IonButton,
    IonIcon,
    ErrorMessage,
  },
  setup() {
    const langGeneric = LanguageService.getTranslator("generic");
    
    const done = () => CreateService.configureNext();
    return {
      langGeneric,
      checkmark,
      done
    }
  }
});
