import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_header = _resolveComponent("create-header")!
  const _component_create_progress_bar = _resolveComponent("create-progress-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_create_header, {
      title: _ctx.title,
      backTo: "/create/modules"
    }, null, 8, ["title"]),
    _createVNode(_component_create_progress_bar, { step: _ctx.progressStep }, null, 8, ["step"])
  ]))
}