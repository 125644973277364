import { HelpResource } from "../HelpResource";

export default {
    de: {
        title: 'Beschreibung',
        detail: 'Fügen sie einen kurzen Hinweis oder eine Beschreibung hinzu. (Optional)'
    },
    en: {
        title: 'Description',
        detail: 'Provide a short description about the use case. (optional)'
    }
} as HelpResource;