
import { defineComponent, onBeforeMount, ref, Ref } from "vue";
import LanguageService from "../../../lib/service/LanguageService";

import { HelpData, HelpResource } from "./help_data/HelpResource";

export default defineComponent({
  name: "HelpPopover",
  props: {
    path: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const content: Ref<HelpData> = ref({
      title: "Loading...",
      detail: "Help text is loading...",
    });
    onBeforeMount(() => {
      content.value = (
        require(`./help_data/${props.path}.ts`).default as HelpResource
      )[LanguageService.lang];
    });
    return {
      content,
    };
  },
});
