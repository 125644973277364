import { HelpResource } from "../../HelpResource";

export default {
    de: {
        title: 'Position',
        detail: 'Geben sie einen Suchbegriff für eine Position ein, z.B. eine Straße mit Hausnummer oder einen Ort. Nutzen sie den GPS-Knopf um ihre aktuellen Koordinaten zu nutzen.'
    },
    en: {
        title: 'Location',
        detail: 'Enter a search query for a position, like a street or a place. Use the GPS-Button to use you current coordinates.'
    }
} as HelpResource;