
import { computed, defineComponent } from "vue";

import CreateService from "../../../lib/service/CreateService";

import CreateHeader from "../CreateHeader.vue";
import CreateProgressBar from "../CreateProgressBar.vue";

export default defineComponent({
  components: {
    CreateHeader,
    CreateProgressBar,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const progressStep = computed<number>(() => {
      return (
        CreateService.baseStepCount + CreateService.countConfiguredModules()
      );
    });
    return {
      progressStep,
    };
  },
});
