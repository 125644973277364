
import { computed, defineComponent } from "vue";

import { add, close } from "ionicons/icons";

import {
  IonItem,
  IonLabel,
  IonInput,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonIcon,
} from "@ionic/vue";

import LanguageService from "../../../lib/service/LanguageService";
import CreateService from "../../../lib/service/CreateService";
import HelpButton from "../../generic/help/HelpButton.vue";

export default defineComponent({
  name: "SetupTitleInput",
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    module: {
      type: String,
      required: true,
    },
  },
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonCard,
    IonButton,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonIcon,
    HelpButton,
  },
  setup(props) {
    const langGeneric = LanguageService.getTranslator("generic");
    const lang = LanguageService.getTranslator("create/module_title");

    const hasSubtitle = computed(() => {
      return (
        (CreateService.info.config as any)[props.module] !== undefined &&
        (CreateService.info.config as any)[props.module].subtitle !== undefined
      );
    });

    const titleValue = computed(() => {
      if ((CreateService.info.config as any)[props.module] !== undefined) {
        return (CreateService.info.config as any)[props.module].title;
      } else {
        return "";
      }
    });

    const subtitleValue = computed(() => {
      if ((CreateService.info.config as any)[props.module] !== undefined) {
        return (CreateService.info.config as any)[props.module].subtitle;
      } else {
        return "";
      }
    });

    const onInputTitle = (event: InputEvent) => {
      (CreateService.info.config as any)[props.module].title = (
        event.target as HTMLInputElement
      ).value;
    };

    const onInputSubtitle = (event: InputEvent) => {
      (CreateService.info.config as any)[props.module].subtitle = (
        event.target as HTMLInputElement
      ).value;
    };

    const addSubtitle = () => {
      if ((CreateService.info.config as any)[props.module] !== undefined) {
        (CreateService.info.config as any)[props.module].subtitle = "";
      }
    };

    const removeSubtitle = () => {
      if ((CreateService.info.config as any)[props.module] !== undefined) {
        (CreateService.info.config as any)[props.module].subtitle = undefined;
      }
    };

    return {
      lang,
      langGeneric,
      add,
      close,
      hasSubtitle,
      titleValue,
      subtitleValue,
      onInputSubtitle,
      onInputTitle,
      addSubtitle,
      removeSubtitle
    };
  },
  methods: {},
});
