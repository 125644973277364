import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"display":"flex","width":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_help_button = _resolveComponent("help-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "h-fit" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.langGeneric.t("title")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "floating" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, {
                maxlength: "30",
                value: _ctx.titleValue,
                placeholder: _ctx.langGeneric.t('enterTitle'),
                onInput: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onInputTitle($event)), ["stop"]))
              }, null, 8, ["value", "placeholder"])
            ]),
            _: 1
          }),
          (_ctx.hasSubtitle)
            ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { position: "floating" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.lang.t("description")) + ": ", 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_ion_input, {
                      placeholder: _ctx.lang.t('enterDescription'),
                      value: _ctx.subtitleValue,
                      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInputSubtitle($event)))
                    }, null, 8, ["placeholder", "value"]),
                    _createVNode(_component_ion_button, {
                      fill: "clear",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeSubtitle()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    size: "small",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addSubtitle())),
                    fill: "clear"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.add,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.lang.t("addSubtitle")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_help_button, {
                    path: "create/subtitle",
                    right: true
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}