import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    fill: "clear",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showHelp($event))),
    class: _normalizeClass(["btn", { 'btn-right': _ctx.right, 'btn-top': _ctx.top }]),
    color: "medium"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_icon, { icon: _ctx.helpCircleOutline }, null, 8, ["icon"])
    ]),
    _: 1
  }, 8, ["class"]))
}